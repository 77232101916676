export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "titleEn",
    sortable: true,
    sortField: "titleEn",
    label: "field.titleEn",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "titleKm",
    sortable: true,
    sortField: "titleKm",
    label: "field.titleKm",
  },
  {
    key: "weight",
    sortable: true,
    sortField: "weight",
    label: "field.weight",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "objectiveType",
    sortable: true,
    sortField: "objectiveType",
    label: "field.objectiveType",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "status",
    label: "field.status",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "id",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedAt",
    sortable: true,
    sortField: "updatedAt",
    label: "field.updatedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedBy",
    label: "field.updatedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
